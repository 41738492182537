import {Injectable} from '@angular/core';
import {OAuthConfig} from './OAuthConfig';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {WebHttpUrlEncodingCodec} from './encoder';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OAuthService {

  private config: OAuthConfig;
  private access_token: string;
  private refresh_token: string;
  private scopes = '';
  private expire: number;
  private id_client: string;
  private status: number;

  constructor(private http: HttpClient) {
    this.loadLocalStorage();
  }

  /**
   * Configura OAuth
   * @param {OAuthConfig} config Configuracion
   */
  public configure(config: OAuthConfig) {
    this.config = config;
    this.validMaintenance();
  }

  private validMaintenance(){
    this.http.get(this.config.urlStatus)
      .subscribe(
        status => {
          this.status = status['status'];
        },
        err => {
          this.status = 0;
        }
      );
  }

  /**
   * Intenta realizar un login
   */
  public tryLogin() {
    this.refreshToken();
  }

  public isMaintenance(): boolean {
    return this.status === 0;
  }

  public checkMaintenance(): any {
    return this.http.get(this.config.urlStatus);
  }

  /**
   * Se obtienen los roles del usuario
   * @returns {string} Roles del usuario en cadena separados por espacios
   */
  public getRoles(): string {
    return this.scopes;
  }

  /**
   * Sale de la sesion, eliminado todos los datos
   */
  public logout() {
    this.expire = -10000;
    this.access_token = '';
    this.refresh_token = '';
    this.scopes = '';
    this.saveLocalStorage();
  }

  /**
   * Determina si es válido el token
   * @returns {boolean} True si es valido, False en otro caso
   */
  public hasValidIdToken() {
    this.validMaintenance();
    const now = new Date();
    if (isNaN(this.expire) || this.expire == null || this.expire < now.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Determina si los roles enviados son validos para ese usuario
   * @param {Array<string>} roles Roles a los que necesita acceso
   * @returns {boolean} True si tiene acceso, False en otro caso
   */
  public hasValidRoles(roles: Array<string>) {
    let isValid = true;
    const obj = this;
    if(this.scopes == null || this.scopes == undefined){
      this.scopes = localStorage.getItem('scopes');
    }
    roles.forEach(function (role) {
      if (!obj.scopes.includes(role)) {
        isValid = false;
      }
    });
    return isValid;
  }

  /**
   * Establece el tiempo de expiracion de la sesion
   * @param expire TimeStamp de la fecha
   */
  private setExpire(expire) {
    expire = parseInt(expire, 10);
    const now = new Date();
    this.expire = new Date(now.getTime() + (expire / 60) * 60000).getTime();
  }


  /**
   * Carga los datos del local storage
   */
  private loadLocalStorage() {
    this.access_token = localStorage.getItem('access_token');
    this.refresh_token = localStorage.getItem('refresh_token');
    this.id_client = localStorage.getItem('id_client');
    this.setExpire(localStorage.getItem('expire'));
    this.scopes = localStorage.getItem('scopes');
  }

  /**
   * Guarda los datos en el local storage
   */
  private saveLocalStorage() {
    localStorage.setItem('access_token', this.access_token);
    localStorage.setItem('refresh_token', this.refresh_token);
    localStorage.setItem('id_client', this.id_client);
    localStorage.setItem('expire', this.expire.toString());
    localStorage.setItem('scopes', this.scopes);
  }

  /**
   * Pide un nuevo access token a partir del refresh token
   */
  public refreshToken() {
    let headers: HttpHeaders = new HttpHeaders();
    const params = new HttpParams({encoder: new WebHttpUrlEncodingCodec()})
      .set('grant_type', 'refresh_token')
      .set('client_id', this.id_client)
      .set('client_secret', this.id_client)
      .set('refresh_token', this.refresh_token);

    headers = headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    this.http.post(this.config.urlRefresh, params, {headers})
      .subscribe(
        tokenResponse => {
          this.access_token = tokenResponse['access_token'];
          this.refresh_token = tokenResponse['refresh_token'];
          this.scopes = tokenResponse['scope'];
          this.setExpire(tokenResponse['expires_in']);
          this.saveLocalStorage();
        },
        err => {
          this.access_token = null;
          this.refresh_token = null;
          this.scopes = null;
        }
      );
  }

  public loginWithPassword(username: string, password: string, headers: HttpHeaders = new HttpHeaders()): Promise<object> {
    return new Promise((resolve, reject) => {

      const params = new HttpParams({encoder: new WebHttpUrlEncodingCodec()})
        .set('grant_type', 'password')
        .set('client_id', username)
        .set('client_secret', username)
        .set('username', username)
        .set('scope', 'refresh_token')
        .set('password', password);

      headers = headers.set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );

      this.http.post(this.config.urlLogin, params, {headers})
        .subscribe(
          tokenResponse => {
            this.id_client = username;
            this.access_token = tokenResponse['access_token'];
            this.refresh_token = tokenResponse['refresh_token'];
            this.scopes = tokenResponse['scope'];
            this.setExpire(tokenResponse['expires_in']);
            this.saveLocalStorage();
            resolve(tokenResponse);
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
