import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {OAuthService} from '../shared/OAuthService';

@Component({
  selector: 'app-mantenimiento',
  templateUrl: './mantenimiento.component.html'
})
export class MantenimientoComponent {

  public error: string;

  constructor(private oauthService: OAuthService, private router: Router) {
    this.oauthService.checkMaintenance().subscribe(
      status => {
        if (status['status'] === 1) {
          this.router.navigate(['/']);
        }
      }
    );
  }


}
