import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {OAuthService} from './OAuthService';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.oauthService.isMaintenance()) {
      this.router.navigate(['/mantenimiento']);
      return false;
    }

    if (this.oauthService.hasValidIdToken()) {
      const roles = route.data['roles'] as Array<string>;

      if (this.oauthService.hasValidRoles(roles)) {
        return true;
      } else {
        return false;
      }

    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }
}
