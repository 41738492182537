import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {APP_BASE_HREF, CommonModule} from '@angular/common';


import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {BsDropdownModule} from 'ngx-bootstrap';
import { LoginComponent } from './login/login.component';
import {AuthGuard} from './shared/AuthGuard';
import {FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MantenimientoComponent} from './mantenimiento/mantenimiento.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MantenimientoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'}, AuthGuard
  ],
})



export class AppModule {
}
