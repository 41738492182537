import {Component} from '@angular/core';
import {OAuthService} from './shared/OAuthService';
import {OAuthConfig} from './shared/OAuthConfig';


export const authConfig: OAuthConfig = {
  urlLogin: 'http://94.23.119.20/login',
  urlRefresh: 'http://94.23.119.20/token',
  urlToken: 'http://94.23.119.20/valid',
  urlStatus: 'http://94.23.119.20/status'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {

  constructor(private oauthService: OAuthService) {
    this.configureWithNewConfigApi();
  }

  private configureWithNewConfigApi() {
    this.oauthService.configure(authConfig);
    this.oauthService.tryLogin();
  }
}
