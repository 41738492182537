import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './shared/AuthGuard';
import {MantenimientoComponent} from './mantenimiento/mantenimiento.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'mantenimiento', component: MantenimientoComponent},
  {
    path: 'panel',
    loadChildren: './panel/panel.module#PanelModule',
    canActivate: [AuthGuard],
    data: {roles: []}
  },
  {path: '', redirectTo: 'panel', pathMatch: 'full'},
  {path: '**', redirectTo: 'panel'},
];

const config: ExtraOptions = {
  useHash: true,
};


@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
