import {Component, OnInit} from '@angular/core';
import {OAuthService} from '../shared/OAuthService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string;
  public password: string;
  public error: string;

  constructor(private oauthService: OAuthService, private router: Router) {
  }

  ngOnInit() {
    if (this.oauthService.hasValidIdToken()) {
      this.router.navigate(['/panel']);
    }
  }


  login() {
    this.oauthService.loginWithPassword(
      this.username, this.password
    )
      .then((tokenResponse) => {
        this.router.navigate(['/panel']);
      })
      .catch(err => {
        this.error = 'Email o contraseña incorrectos';
      });
    return false;
  }

}
